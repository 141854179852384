import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Link from '../Link'

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  line-height: 1.5em;
`

const StyledTime = styled.time`
  flex-shrink: 0;
`

function PostItem({ post }) {
  const {
    fields: { slug },
    frontmatter: { title, rawDate, date }
  } = post
  return (
    <StyledListItem>
      <Link to={`/blog/${slug}`}>{title}</Link>
      <StyledTime dateTime={rawDate}>{date}</StyledTime>
    </StyledListItem>
  )
}

PostItem.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired,
    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired,
      dateYear: PropTypes.string.isRequired,
      rawDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default PostItem
