import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { H3 } from '../components/Heading'
import PostItem from '../components/PostItem'

export const query = graphql`
  query SitePostsQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "DD MMM")
          dateYear: date(formatString: "Y")
          rawDate: date(formatString: "X")
        }
        fields {
          slug
        }
      }
    }
  }
`

function formatPostData(postNodes) {
  const posts = {}
  let years = []

  postNodes.forEach(node => {
    const postYear = node.frontmatter.dateYear

    if (!Object.prototype.hasOwnProperty.call(posts, postYear)) {
      posts[postYear] = []
    }
    posts[postYear].push(node)
    years.push(postYear)
  })

  years = years.filter((item, position) => {
    return years.indexOf(item) === position
  })

  return {
    posts,
    years
  }
}

function BlogPage({ data }) {
  const {
    allMarkdownRemark: { nodes }
  } = data

  const postData = formatPostData(nodes)

  return (
    <Layout>
      <main>
        {postData.years.map(year => {
          return (
            <Fragment key={year}>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <H3>{year} ¬</H3>
              <ul>
                {postData.posts[year].map(post => (
                  <PostItem key={post.id} post={post} />
                ))}
              </ul>
            </Fragment>
          )
        })}
      </main>
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          fields: PropTypes.shape({
            slug: PropTypes.string.isRequired
          }).isRequired,
          frontmatter: PropTypes.shape({
            date: PropTypes.string.isRequired,
            dateYear: PropTypes.string.isRequired,
            rawDate: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
          }).isRequired
        })
      )
    })
  }).isRequired
}

export default BlogPage
